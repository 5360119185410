import React from "react";
import Link from "next/link";

export const Logo = (props) => {
    const {color, noText} = props;
  return (
    <>
    <Link href="/">
      <a>
      <span link-meta="/" className={"u-flexCenter u-cursorPointer u-fontWeightBold u-fontSize28 u-textColorNormal " + (color === "white" ? " u-textColorWhite" : " u-textColorSecondary")}>
        <img style={{height: 55}} className="" src={color === "white" ? "/static/images/logo-light.png" : "/static/images/logo.png"} /> {noText ? "" : <span style={{fontFamily:"Lobster Two, cursive"}} className="u-marginLeft15">fed.by</span>}
      </span>
      </a>
    </Link>
    </>
  );
};
